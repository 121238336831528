// Generated by Framer (38f2e7f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { themeSwicther } from "../codeFile/Dark_Light_Mode.tsx";
const MotionDivThemeSwicther = themeSwicther(motion.div);

const cycleOrder = ["bbpj9jcW5", "PdgN5f9xY", "e__5J1Wne"];

const serializationHash = "framer-nFI7j"

const variantClassNames = {bbpj9jcW5: "framer-v-qia8u9", e__5J1Wne: "framer-v-15sfu1k", PdgN5f9xY: "framer-v-bymebo"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translateY(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 2": "PdgN5f9xY", "Variant 3": "e__5J1Wne", Desktop: "bbpj9jcW5"}

const getProps = ({height, id, mouseEnter, width, ...props}) => { return {...props, KERHI4g51: mouseEnter ?? props.KERHI4g51, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "bbpj9jcW5"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;mouseEnter?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, KERHI4g51, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "bbpj9jcW5", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnteroqecd = activeVariantCallback(async (...args) => {
setGestureState({isHovered: true})
if (KERHI4g51) {const res = await KERHI4g51(...args);
if (res === false) return false;}
setVariant("PdgN5f9xY")
})

const onTapy5ied0 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
setVariant("e__5J1Wne")
})

const onMouseLeave6ki6y9 = activeVariantCallback(async (...args) => {
setGestureState({isHovered: false})
setVariant("bbpj9jcW5")
})

const onTap1fw2vb5 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
setVariant("PdgN5f9xY")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><MotionDivThemeSwicther {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-qia8u9", className, classNames)} data-framer-name={"Desktop"} data-highlight layoutDependency={layoutDependency} layoutId={"bbpj9jcW5"} onMouseEnter={onMouseEnteroqecd} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({e__5J1Wne: {"data-framer-name": "Variant 3", onMouseLeave: onMouseLeave6ki6y9, onTap: onTap1fw2vb5}, PdgN5f9xY: {"data-framer-name": "Variant 2", onMouseLeave: onMouseLeave6ki6y9, onTap: onTapy5ied0}}, baseVariant, gestureVariant)}><motion.div className={"framer-1dwfr39"} layoutDependency={layoutDependency} layoutId={"gz9ri7kPx"} style={{backgroundColor: "var(--token-74eb8845-2c5b-4972-bb2a-40be173f95c3, rgba(79, 79, 79, 0.25))", borderBottomLeftRadius: 38, borderBottomRightRadius: 38, borderTopLeftRadius: 38, borderTopRightRadius: 38}}><MotionDivThemeSwicther className={"framer-hv4rsl"} layoutDependency={layoutDependency} layoutId={"Va7Ue_DhW"} style={{backgroundColor: "var(--token-a31d5043-947c-4b72-8ac5-3a2cd17fc6f0, rgb(38, 38, 38))", borderBottomLeftRadius: 99, borderBottomRightRadius: 99, borderTopLeftRadius: 99, borderTopRightRadius: 99}} transformTemplate={transformTemplate1}/></motion.div></MotionDivThemeSwicther></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-nFI7j.framer-1myd45t, .framer-nFI7j .framer-1myd45t { display: block; }", ".framer-nFI7j.framer-qia8u9 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 2px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-nFI7j .framer-1dwfr39 { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 20px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 20px; will-change: var(--framer-will-change-override, transform); }", ".framer-nFI7j .framer-hv4rsl { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 20px); left: 0px; overflow: hidden; position: absolute; top: 50%; width: 20px; will-change: var(--framer-will-change-override, transform); z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-nFI7j.framer-qia8u9, .framer-nFI7j .framer-1dwfr39 { gap: 0px; } .framer-nFI7j.framer-qia8u9 > * { margin: 0px; margin-left: calc(2px / 2); margin-right: calc(2px / 2); } .framer-nFI7j.framer-qia8u9 > :first-child, .framer-nFI7j .framer-1dwfr39 > :first-child { margin-left: 0px; } .framer-nFI7j.framer-qia8u9 > :last-child, .framer-nFI7j .framer-1dwfr39 > :last-child { margin-right: 0px; } .framer-nFI7j .framer-1dwfr39 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } }", ".framer-nFI7j.framer-v-bymebo .framer-1dwfr39, .framer-nFI7j.framer-v-15sfu1k .framer-1dwfr39 { height: 24px; width: 45px; }", ".framer-nFI7j.framer-v-bymebo .framer-hv4rsl { left: 2px; }", ".framer-nFI7j.framer-v-15sfu1k .framer-hv4rsl { left: 23px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 20
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"PdgN5f9xY":{"layout":["auto","auto"]},"e__5J1Wne":{"layout":["auto","auto"]}}}
 * @framerVariables {"KERHI4g51":"mouseEnter"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameryE9Ebz0BF: React.ComponentType<Props> = withCSS(Component, css, "framer-nFI7j") as typeof Component;
export default FrameryE9Ebz0BF;

FrameryE9Ebz0BF.displayName = "Navigation / New Theme Switch";

FrameryE9Ebz0BF.defaultProps = {height: 20, width: 20};

addPropertyControls(FrameryE9Ebz0BF, {variant: {options: ["bbpj9jcW5", "PdgN5f9xY", "e__5J1Wne"], optionTitles: ["Desktop", "Variant 2", "Variant 3"], title: "Variant", type: ControlType.Enum}, KERHI4g51: {title: "Mouse Enter", type: ControlType.EventHandler}} as any)

addFonts(FrameryE9Ebz0BF, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})